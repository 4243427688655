<template>
  <div>
    <div class="info-header mb-5">
      <div>
        <img src="../../../assets/images/MDI-information-outline-new.svg" />
      </div>
      <div>
        <p class="p4 color-green-2">
          Se o treinamento selecionado já tiver um certificado ativo nas mesmas
          datas, a data de início ou fim será ajustada automaticamente para
          evitar duplicidade, e certificados posteriores serão inativos, caso
          não haja uma data fim definida.
        </p>
      </div>
    </div>
    <b-card border-variant="light" class="custom-card pt-2 pb-2">
      <b-form-row class="custom-text pl-3 pr-5">
        <b-col> TREINAMENTO </b-col>
        <b-col>
          <b-form-select
            v-model.lazy.trim="form.idTraining"
            :state="validateField('idTraining')"
            :options="trainingOptions"
            ref="idTraining"
            v-on:change="onChangeTraining"
          />
        </b-col>
      </b-form-row>
      <b-form-row class="alter-form pl-3 pr-5 pt-2 pb-2">
        <b-col>
          STATUS
        </b-col>
        <b-col>
          <b-form-checkbox
            size="lg"
            class="ml-auto align-right-all-time"
            switch
            v-model="statusActivation"
            @change="this.onChangedStatus"
          >
            {{ statusActivation ? "Ativo" : "Inativo" }}
          </b-form-checkbox>
        </b-col>
      </b-form-row>
      <b-form-row class="pl-3 pt-2 pb-2">
        <b-col class="custom-text">
          DATA INÍCIO DO VÍNCULO
          <p v-show="this.dataLimitStartLinked">
            A partir da data: {{ this.dataLimitStartLinked }}
          </p>
        </b-col>
        <b-col>
          <date-picker
            datePickerId="dateStarted"
            :key="getKeyDate(form.dateStarted)"
            :dataValue="form.dateStarted"
            :state="onValidateFieldCustom('dateStarted', dataStartErrorMsg)"
            @datePickerChangedValue="onChangedDateStarted"
            :required="true"
          />
        </b-col>
        <div
          class="invalid text-invalid-right pr-5"
          v-show="dataStartErrorMsg != null"
        >
          {{ dataStartErrorMsg }}
        </div>
      </b-form-row>
      <b-form-row class="pl-3 pr-5 pt-2 pb-2">
        <b-col class="custom-text">
          FIM DO VÍNCULO
          <p v-show="this.dataLimitFinalLinked">
            A partir da data: {{ this.dataLimitFinalLinked }}
          </p>
        </b-col>
        <b-col>
          <b-form-select
            v-model.lazy.trim="form.type"
            :options="typeOptions"
            ref="type"
            v-on:change="onChangeType(form.type)"
          />
        </b-col>
        <div
          class="invalid text-invalid-right"
          v-show="dataFinalErrorMsg != null && this.form.type == 0"
        >
          {{ dataFinalErrorMsg }}
        </div>
      </b-form-row>
      <b-form-row class="alter-form pl-3 pt-2 pb-2" v-show="this.form.type == 1">
        <b-col>
          INFORME A DATA DO FIM
        </b-col>
        <b-col>
          <date-picker
            datePickerId="dateFinished"
            :key="getKeyDate(form.dateFinished)"
            :dataValue="form.dateFinished"
            :state="onCheckFinishedDate('dateFinished')" 
            @datePickerChangedValue="onChangedDateFinished"
            :required="true"
          />
        </b-col>
        <div
          class="invalid text-invalid-right pr-5"
          v-show="dataFinalErrorMsg != null"
        >
          {{ dataFinalErrorMsg }}
        </div>
      </b-form-row>
      <div class="button-edit-container" v-if="form.idLinkCertificateTraining">
        <div class="box-button-edit">
          <b-button
            form="alter-form"
            type="button"
            class="ml-4"
            variant="outline-primary"
            id="submitButtonText"
            @click="this.resetForm"
          >
            Cancelar
          </b-button>
        </div>
        <div class="box-button-edit">
          <b-button
            form="alter-form"
            type="button"
            class="ml-4"
            variant="primary"
            id="submitButtonText"
            @click="this.onVincularHandler"
          >
            Salvar
          </b-button>
        </div>
      </div>
      <b-form-row class="pt-3 pb-5 pr-5" v-else>
        <b-col class="button-include d-flex justify-content-end">
          <b-button
            form="alter-form"
            type="button"
            class="ml-4"
            variant="primary"
            id="submitButtonText"
            @click="this.onVincularHandler"
          >
            Vincular
          </b-button>
        </b-col>
      </b-form-row>

      <table class="table b-table table-custom spaced-text">
        <thead>
          <tr>
            <th scope="col">Certificados já vinculados</th>
            <th scope="col" class="text-center">Início Vínculo</th>
            <th scope="col" class="text-center">Fim Vínculo</th>
            <th scope="col" class="text-center">Status</th>
          </tr>
        </thead>
        <draggable v-model="listCertifications" tag="tbody">
          <tr v-for="item in listCertifications" :key="item.id">
            <td>{{ item.name }}</td>
            <td class="text-center">
              {{ item.startDate }}
              <span
                v-if="item.startDateObj.warn && item.statusActivation == null"
                id="disabled-wrapper"
                class="d-inline-block"
                tabindex="1"
              >
                <img
                  :id="item.id + item.startDate"
                  src="@/assets/icons/icon_alert.svg"
                />
              </span>
              <b-tooltip
                v-if="item.startDateObj.warn && item.statusActivation == null"
                placement="bottom"
                key="PF-1"
                :target="item.id + item.startDate"
              >
                Esta data será alterada para a data
                {{ this.onGetDateFormated(item.startDateObj.toDate) }}
              </b-tooltip>
            </td>
            <td class="text-center">
              {{ item.endDate }}
              <span
                v-if="item.endDateObj.warn && item.statusActivation == null"
                id="disabled-wrapper"
                class="d-inline-block"
                tabindex="1"
              >
                <img
                  :id="item.id + item.endDate"
                  src="@/assets/icons/icon_alert.svg"
                />
              </span>
              <b-tooltip
                v-if="item.endDateObj.warn && item.statusActivation == null"
                placement="bottom"
                key="PF-2"
                :target="item.id + item.endDate"
              >
                Esta data será alterada para a data 
                {{ onGetDateFormated(item.endDateObj.toDate) }}
              </b-tooltip>
            </td>
            <td class="alter-form text-center">
              <b-form-checkbox
                size="lg"
                class="w-100 custom-cursor-status "
                switch
                v-model="item.status"
                :disabled="true"
              >
                <span
                  v-if="item.statusActivation != null"
                  id="disabled-wrapper"
                  class="d-inline-block"
                  tabindex="1"
                >
                  <img
                    :id="item.id + item.endDate + item.status"
                    src="@/assets/icons/icon_alert.svg"
                  />
                </span>
                <b-tooltip
                  v-if="item.statusActivation != null"
                  placement="bottom"
                  key="PF-2"
                  :target="item.id + item.endDate + item.status"
                >
                  Este treinamento será desativado com base na
                  data mencionada acima.
                </b-tooltip>
              </b-form-checkbox>
            </td>
          </tr>
        </draggable>
      </table>
    </b-card>
    <div class="text-div-table ">
      Treinamentos vinculados ao certificado - {{ this.certificate.name }}
    </div>
    <b-card border-variant="light" class="custom-card" v-show="!this.loading">
      <b-table
        responsive
        class="table-training-links spaced-text"
        :fields="fieldsTrainingLink"
        :items="listTrainings"
        v-show="!this.loading"
        show-empty
        empty-text="Nenhuma informação para exibir!"
      >
        <template #cell(status)="data">
          <b-form-checkbox
            size="lg"
            class="w-100 custom-cursor-status "
            switch
            v-model="data.item.statusBoolean"
            :disabled="true"
          >
          </b-form-checkbox>
        </template>

        <template #cell(action)="data">
          <div class="custom-actions d-flex justify-content-between">
            <div
              class="icon custom-icon-edit"
              @click="eventEdit(data.item)"
              data-toggle="tooltip"
              data-placement="top"
              title="Editar"
            ></div>
            <div
              class="icon custom-icon-delete"
              :class="
                data.item.action.canDelete
                  ? 'icon-delete-enable'
                  : 'icon-delete-disable'
              "
              @click="eventDelete(data.item)"
              data-toggle="tooltip"
              data-placement="top"
              title="Excluir"
            ></div>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-row class="my-5">
      <b-button
        variant="outline-primary"
        :disabled="this.loading"
        @click="this.onCancel"
        class="ml-4"
      >
        Concluir
      </b-button>
    </b-row>
    <AttentionModal
      modalId="delete-link-modal"
      title="Atenção!"
      submitButtonText="Sim, excluir"
      cancelButtonText="Não tenho certeza"
      ref="delete-link-modal"
      :onAction="this.onDeleteConfirm"
    >
      <template slot="description">
        <p class="p1 mb-5 text-center">
          Tem certeza que deseja excluir o vínculo deste treinamento com o
          certificado?
        </p>
      </template>
    </AttentionModal>
    <success-modal
      ref="success-modal"
      :description="this.msgSuccess"
      buttonText="Ok, entendi!"
    />
    <AttentionModal
      modalId="attention-modal"
      title="Atenção!"
      submitButtonText="Sim, vincular e ajustar"
      cancelButtonText="Não tenho certeza"
      :onAction="onActionAttentionModal"
      ref="attention-modal"
      ><template slot="description">
        <div class="d-flex">
          <div>
            <p class="p1 mb-5 text-center">
              O período indicado do vínculo com o treinamento irá conflitar com
              o período de um ou mais certificados, conforme datas indicadas em
              laranja.
            </p>
            <p class="p1 mb-5 text-center">
              Tem certeza que deseja vincular o certificado com essas datas? O
              período e status dos demais certificados serão ajustados
              automaticamente.
            </p>
          </div>
        </div>
      </template>
    </AttentionModal>
  </div>
</template>

<script>
import DatePicker from "../../../components/date-picker/DatePicker.vue";
import { dateFormat, formatDateToBootstrapPicker } from "../../../utils/date/date-utils";

import {
  validateCheckBox,
  validateField,
  requiredFieldsFilled,
  validateFieldStartDate,
  validateFieldFile,
} from "../../../utils/validate/validate.js";
import { inject } from "@vue/composition-api";
import draggable from "vuedraggable";
import { createToast } from "../../../components/toast/toast";
import {
  getLinkedCertificates,
  getRulesDataByCertificates,
  getRulesDataByTraining,
  getTrainings,
  saveLinkCertificateTraining,
  getLinkedTrainings,
  deleteLink,
  getCertificate,
} from "../../../services/certificate/certificateManagementService";
import AttentionModal from "../../../components/base-modals/AttentionModal.vue";
import SuccessModal from "../../../components/base-modals/SuccessModal.vue";

const UNDETERMINED = "Indeterminado";

const TYPE = {
  UNDETERMINED: 0,
  WITH_DATE: 1,
};

const DEFAULT_FORM = {
  dateStarted: null,
  dateFinished: null,
  status: true,
  idTraining: null,
  type: TYPE.UNDETERMINED,
};
export default {
  name: "links-training",
  props: ["onChangeTab", "index"],
  components: { DatePicker, draggable, AttentionModal, SuccessModal },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  data() {
    return {
      fieldsTrainingLink: [
        {
          key: "nameTraining",
          label: "Treinamento",
          thStyle: { width: "50%" },
        },
        {
          key: "startDate",
          label: "Início",
          thStyle: { width: "15%" },
        },
        {
          key: "endDate",
          label: "Fim",
          thStyle: { width: "15%" },
        },
        {
          key: "numberCertificatesIssued",
          label: "Emitidos",
          class: "text-center",
          thStyle: { width: "10%" },
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          thStyle: { width: "5%" },
        },
        {
          key: "action",
          label: "Ações",
          class: "text-center",
          thStyle: { width: "5%" },
        },
      ],
      itemToDelete: null,
      msgSuccess: null,
      dataLimitFinalLinked: null,
      dataLimitStartLinked: null,
      typeOptions: [],
      rulesCertificates: [],
      certificate: {},
      dataStartErrorMsg: null,
      dataFinalErrorMsg: null,
      statusActivation: null,
      formSubmited: false,
      dateStartedKey: null,
      dateFinishedKey: null,
      loading: false,
      originalValues: DEFAULT_FORM,
      form: DEFAULT_FORM,
      files: [],
      validations: {},
      trainingOptions: [],
      listCertifications: [],
      listTrainings: [],
      validateField,
      validateCheckBox,
      requiredFieldsFilled,
      validateFieldFile,
      validateFieldStartDate,
    };
  },
  async mounted() {
    this.globalLoading = false;
    if (!this.$route.params.id) {
      createToast(
        "Atenção",
        "Informações do certificado não foram cadastradas.",
        "warning"
      );
      this.$props.onChangeTab(this.$props.index - 1);
      return;
    }
    this.certificate = await getCertificate(this.$route.params.id);
    this.listTrainings = await getLinkedTrainings(this.$route.params.id);
    this.init();
    this.initTypeOptions();
    this.initTrainingOptions();
  },
  methods: {
    init() {
      this.form.status = "ENABLED";
      this.form.dateStarted = null;
      this.form.dateFinished = null;
      this.form.type = TYPE.UNDETERMINED;
      this.listCertifications = [];
      this.clearError();
      this.clearRules();
      this.onChangedStatus();
    },
    onGetDateFormated(date) {
      return dateFormat(date);
    },
    initTypeOptions() {
      this.typeOptions = [
        {
          value: 0,
          text: "Inderterminado",
        },
        {
          value: 1,
          text: "Informar data do fim",
        },
      ];
    },
    async initTrainingOptions() {
      this.trainingOptions = await this.getCertificateTrainings();
    },
    async getCertificateTrainings() {
      this.loading = true;
      try {
        var responseData = await getTrainings();
        var response = this.buildSelectCertificateTrainingsOptions(
          responseData
        );
        this.loading = false;
        return response;
      } catch (error) {
        this.loading = false;
        console.error("getCertificateTrainings", error);
        createToast(
          "Atenção",
          "Ocorreu um erro carregar as informações de treinamentos."
        );
      }
    },
    buildSelectCertificateTrainingsOptions(formDataOptions) {
      return [
        { value: null, text: "Selecione um treinamento", disabled: true },
        ...formDataOptions.map((formData) => ({
          value: formData.idTrainig,
          text: formData.training,
        })),
      ];
    },
    async onChangeTraining() {
      try {
        this.init();
        const request = {
          idCertificate: this.$route.params.id,
          idTraining: this.form.idTraining,
        };
        const responseData = await getRulesDataByTraining(request);
        this.dataLimitFinalLinked = responseData.endDate;
        this.dataLimitStartLinked = responseData.startDate;

        this.listCertifications = await this.getListCertificationsLinked();
      } catch (error) {
        this.loading = false;
        console.error("onChangeTraining", error);
        createToast(
          "Atenção",
          "Ocorreu um erro carregar as regras de data trainamento."
        );
      }
    },
    onChangeType(type) {
      this.clearError();
      this.clearRules();
      this.form.dateFinished = null;
      if (type == TYPE.UNDETERMINED && this.form.idTraining != null) {
        if (
          this.form.dateStarted != null &&
          this.form.dateStarted != undefined
        ) {
          this.getRulesCertificates();
        }
      }
    },
    async getRulesCertificates() {
      const request = {
        idLinkCertificateTraining: null,
        idCertificate: this.$route.params.id,
        idTraining: this.form.idTraining,
        startDate: this.form.dateStarted,
        endDate:
          this.form.type == TYPE.WITH_DATE ? this.form.dateFinished : null,
      };

      try {
        this.rulesCertificates = await getRulesDataByCertificates(request);
        this.getCertificateEditing(this.rulesCertificates);
        this.updateFeedbackGridCertificates();
      } catch (error) {
        this.loading = false;
        console.error("getRulesCertificates", error);
        createToast(
          "Atenção",
          "Ocorreu um erro carregar as regras de data dos certificados vinculados."
        );
      }
    },
    clearRules() {
      this.rulesCertificates = [];
      this.updateFeedbackGridCertificates();
    },
    getCertificateEditing(certificates) {
      const certificate = certificates.filter(
        (cert) => cert.editing == true
      )[0];
      if (certificate != null) {
        this.dataStartErrorMsg = certificate.startDate.error;
        this.dataFinalErrorMsg = certificate.endDate.error;
      }
    },
    updateFeedbackGridCertificates() {
      const obj = {
        warn: null,
        error: null,
      };

      this.listCertifications.forEach((cert) => {
        const certRule = this.rulesCertificates.filter(
          (rule) => rule.id == cert.id
        )[0];
        if (certRule != null && certRule != undefined) {
          cert.statusActivation = certRule.status;
          cert.startDateObj = certRule.startDate;
          cert.endDateObj = certRule.endDate;
        } else {
          cert.statusActivation = null;
          cert.startDateObj = obj;
          cert.endDateObj = obj;
        }
      });
    },
    clearError() {
      this.dataStartErrorMsg = null;
      this.dataFinalErrorMsg = null;
    },
    getKeyDate(date) {
      return date?.toString();
    },
    onChangedDateFinished(value) {
      this.clearError();
      this.form.dateFinished = value;

      if (!value) {
        this.clearRules();
        return;
      }

      if (this.form.idTraining != null) {
        if (this.form.type == TYPE.WITH_DATE && this.form.dateStarted) {
          this.getRulesCertificates();
        }
      }
    },
    onChangedDateStarted(value) {
      this.clearError();
      this.form.dateStarted = value;

      if (!value) {
        this.clearRules();
        return;
      }

      if (this.form.idTraining) {
        if (
          this.form.type == TYPE.UNDETERMINED ||
          (this.form.type == TYPE.WITH_DATE && this.form.dateFinished)
        ) {
          this.getRulesCertificates();
        }
      }
    },
    async onActionAttentionModal(action) {
      if (action) {
        this.onSaveLinkedTraining();
      }
      this.formSubmited = false;
    },
    async onSaveLinkedTraining() {
      await this.saveLinkedTraining();
      this.listTrainings = await getLinkedTrainings(this.$route.params.id);

      this.listCertifications = await this.getListCertificationsLinked();
      this.resetForm();
    },
    resetForm() {
      this.form.status = true;
      this.form.dateStarted = null;
      this.form.dateFinished = null;
      this.form.type = TYPE.UNDETERMINED;
      this.form.idLinkCertificateTraining = null;
      this.form.idTraining = null;
      this.listCertifications = [];
      this.dataLimitStartLinked = null;
      this.dataLimitFinalLinked = null;
      this.clearError();
      this.clearRules();
      this.onChangedStatus();
    },
    async getListCertificationsLinked() {
      try {
        return await getLinkedCertificates(this.form.idTraining);
      } catch (error) {
        this.loading = false;
        console.error("getListCertificationsLinked", error);
        createToast(
          "Atenção",
          "Ocorreu um erro ao listar os certificados vinculados."
        );
      }
    },
    validateFieldStartDateInternal(field) {
      const resp = validateField(field);
      return this.dataStartErrorMsg == null && resp;
    },
    onValidateFieldCustom(field, msgError) {
      let resp = this.validateField(field);
      if (resp) {
        resp = (resp && msgError == null) || msgError == "";
      } else if (msgError != null && msgError != "") {
        resp = false;
      }
      return resp;
    },
    onCheckFinishedDate(field) {
      if (this.form.type == 0) return null;
      let resp = this.validateField(field);
      
      const msgError = this.dataFinalErrorMsg;

      if (resp != null) {
        resp = (resp && msgError == null) || msgError == "";
      } else if (msgError != null && msgError != "") {
        resp = false;
      }
      return resp;
    },
    requiredFieldsFilledInternal() {
      const isValidDateStart = this.onValidateFieldCustom(
        "dateStarted",
        this.dataStartErrorMsg
      ); 

      const isValidType = this.onValidateFieldCustom('type', this.dataFinalErrorMsg);

      const isValidDateFinal = this.onCheckFinishedDate('dateFinished');
      
      let checkDates = isValidDateStart == null || isValidDateStart == true;
      if (this.form.type == TYPE.WITH_DATE) {
        checkDates = checkDates && (isValidDateFinal == null || isValidDateFinal == true);
      } else {
        checkDates = checkDates && (isValidType == null || isValidType == true);
      }

      const checkRequiredFields = this.requiredFieldsFilled();
      return checkDates && checkRequiredFields;
    },
    onCheckMessageWarn() {
      const isExist = this.listCertifications.find((item) => {
        return item.statusActivation != null  || item.endDateObj.warn != null || item.startDateObj.warn != null;
      });
      return isExist != null;
    },
    onVincularHandler() {
      this.formSubmited = true;

      if (this.requiredFieldsFilledInternal()) {
        this.clearError();
        if (this.onCheckMessageWarn()) {
          const modal = this.$refs["attention-modal"].$children[0];
          modal.show();
        } else {
          this.onSaveLinkedTraining();
          this.formSubmited = false;
        }
      }
    },
    async saveLinkedTraining() {
      try {
        const request = {
          idLinkCertificateTraining: null,
          ...this.form,
          idCertificate: this.$route.params.id,
          statusActivation: this.statusActivation,
        };

        await saveLinkCertificateTraining(request);
      } catch (error) {
        this.loading = false;
        console.error("saveLinkedTraining", error);
        createToast(
          "Atenção",
          "Ocorreu um erro ao salvar o vínculo com o treinamento."
        );
      }
    },
    onChangedStatus() {
      this.statusActivation = this.form.status == "ENABLED";
    },
    async eventEdit(item) {
      this.resetForm();
      this.form = {
        ...this.form,
        idTraining: item.idTraining,
      };
      await this.onChangeTraining();

      this.form = {
        ...this.form,
        idLinkCertificateTraining: item.idLinkCertificateTraining,
        dateStarted: formatDateToBootstrapPicker(item.startDate),
        status: item.status,
        type: item.endDate == UNDETERMINED ? TYPE.UNDETERMINED : TYPE.WITH_DATE,
        dateFinished: this.determineEndDate(item.endDate),
      };
      this.onChangedStatus();
    },
    eventDelete(itemToDelete) {
      this.itemToDelete = itemToDelete;
      const modal = this.$refs["delete-link-modal"].$children[0];
      modal.show();
    },
    async onDeleteConfirm(confirmed) {
      if (!confirmed) {
        this.resetForm();
        return;
      }

      await deleteLink(this.itemToDelete.idLinkCertificateTraining);
      this.msgSuccess = "Vínculo excluído com sucesso!";
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();

      this.listTrainings = await getLinkedTrainings(this.$route.params.id);
    },
    onCancel() {},
    determineEndDate(endDate) {
      if (endDate === UNDETERMINED) {
        return "";
      }
      return formatDateToBootstrapPicker(endDate);
    },
  },
};
</script>
<style lang="scss">
.b-form-tags-button {
  display: none;
}
</style>
<style lang="scss" scoped>
.align-right-all-time {
  position: absolute;
  right: 4px;
}
.b-form-tags-input {
  font-size: 1.2rem;
}
.b-form-tag-content {
  font-size: 1.1rem;
}
.b-form-tag > button.b-form-tag-remove {
  font-size: 180%;
}
.col-tooltip {
  max-width: 0.2rem;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.custom-div-reload {
  display: grid;
  grid-template-columns: auto 24px;
}
.custom-reload {
  cursor: pointer;
}
.icon {
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;

  &-ordering {
    background-image: url("../../../assets/icons/arrow-collapse-vertical.svg");
    float: left;
  }

  &-folder {
    background-image: url("../../../assets/icons/folder-outline.svg");
    margin: auto;
  }

  &-edit {
    background-image: url("../../../assets/icons/pencil-outline.svg");
    float: left;

    &:hover {
      background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
      background-image: url("../../../assets/icons/pencil-outline-houver.svg");
      background-position: center;
      border-radius: 30px;
    }
  }

  &-delete-enable {
    background-image: url("../../../assets/icons/trash-can-outline.svg");
    float: left;

    &:hover {
      background: #fbe9ed 0% 0% no-repeat padding-box;
      background-image: url("../../../assets/icons/trash-can-outline-houver.svg");
      background-position: center;
      border-radius: 30px;
    }
  }

  &-delete-disable {
    float: left;
    background-image: url("../../../assets/icons/trash-can-outline-disable.svg");

    &:hover {
      cursor: default !important;
    }
  }

  &-duplicate {
    background-image: url("../../../assets/icons/clone.svg");
    float: left;
    margin-left: 6px;

    &:hover {
      background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
      background-image: url("../../../assets/icons/clone-hover.svg");
      background-position: center;
      border-radius: 30px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.column-actions-content {
  margin: auto;
  width: 55px;
}

.disable-icon {
  opacity: 49.8%;
  cursor: not-allowed !important;
}

.col-tooltip {
  max-width: 0.2rem;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.align-right-all-time {
  position: absolute;
  right: 4px;
}
.b-form-tags-input {
  font-size: 1.2rem;
}
.b-form-tag-content {
  font-size: 1.1rem;
}
.form-flex-row {
  display: flex;
  flex-direction: row;
}
.custom-text {
  color: var(--grey-1);
}
.text-invalid-right {
  width: 100%;
  text-align: right;
}
.invalid {
  color: #dc3545;
  text-transform: none;
}
.info-header {
  display: grid;
  width: 100%;
  grid-template-columns: 30px auto;
}
.text-div-table {
  font: normal normal normal 16px/19px Trebuchet MS;
  color: #23272b;
  opacity: 1;
  margin-top: 2em;
  margin-bottom: 1em;
}

.custom-icon-edit {
  background-image: url("../../../assets/icons/pencil-outline.svg");
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  border-radius: 30px;
  background-position: center;
  margin-right: 1rem;
  cursor: pointer;
  &:hover {
    background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../../assets/icons/pencil-outline-houver.svg");
    background-position: center;
  }
}

.custom-icon-delete {
  background-image: url("../../../assets/icons/trash-can-outline.svg");
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  border-radius: 30px;
  background-position: center;
  &:hover {
    background: rgba(213, 36, 83, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../../assets/icons/trash-can-outline-houver.svg");
    background-position: center;
  }
}
.custom-actions {
  width: 90%;
}
.button-edit-container {
  display: flex; /* Ativa o Flexbox */
  justify-content: flex-end; /* Alinha os itens à direita */
  gap: 10px;
}
.box-button-edit {
  padding-top: 30px;
  padding-bottom: 16px;
}
.box-button-edit:last-child {
  margin-right: 26px; /* Adiciona o margin-right apenas ao último elemento */
}
</style>
<style lang="scss">
div.table-training-links td {
  color: #7a7e83;
}
</style>
